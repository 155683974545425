<template>
  <div>

    <el-card>
    <el-page-header @back="goBack" :content="title" style="margin-bottom: 30px;">
    </el-page-header>
        <nav class="input">
          <el-input v-model="search.keyword" size="medium" placeholder="输入姓名，昵称，公司，手机" />
        </nav>
        <nav class="input" style="width:150px">
          <el-select v-model="search.status"  placeholder="活动状态">
              <el-option label="全部" :value="-2"></el-option>
              <el-option label="已取消" :value="-1"></el-option>
              <el-option label="待审核" :value="0"></el-option>
              <el-option label="审核通过" :value="1"></el-option>
              <el-option label="审核不通过" :value="2"></el-option>
              <el-option label="已签到" :value="3"></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width:150px">
          <el-select v-model="search.channelId"  placeholder="渠道">
              <el-option label="全部" :value="-1"></el-option>
              <el-option :label="item.name" :value="item.id" v-for="item in channelList"></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width:150px">
          <el-select v-model="search.ticketId"  placeholder="票种">
              <el-option label="全部" :value="-1"></el-option>
              <el-option :label="item.name" :value="item.id" v-for="item in ticketList"></el-option>
          </el-select>
        </nav>

        <nav class="input" style="width: 380px;">
          <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px);height: 40px;"
          >报名时间：</label
        >
          <el-date-picker
            v-model="search.applyDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 300px"
            size="medium"
          />
        </nav>
        <nav class="input" style="width: auto;height:40px" >
          <el-button type="primary" size="small" @click="onSearch">{{
            btn ? '搜索' : 'loading..'
          }}</el-button>
        </nav>
          <nav class="input" style="width: auto;height:40px">
          <el-button @click="add" type="primary" size="small" :disabled="!($store.state.btn_if.indexOf('cooperation_white_add') != -1)">{{
            '添加活动人员'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
      >
        <el-table-column prop="userId" label="ID"> </el-table-column>

        <el-table-column prop="avatar" label="头像" width=180>
          <template slot-scope="{row}">
            <!-- <img :src="baseurl+yu+row.avatar||baseurl + yu + '/static/pc/img/avatar.png'" alt=""> -->
            <el-image :src="baseurl+yu+row.avatar"  :lazy="true" style="width:90px">
              <div slot="error">
                <img :src="baseurl + yu + '/static/pc/img/avatar.png'" alt="" style="width:90px">
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="名字"> </el-table-column>
        <el-table-column prop="nickname" label="昵称"> </el-table-column>
        <el-table-column prop="ticketName" label="票种"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="{row}">
            <el-tag  type="warning" size="small" v-show="fStatus(row.status)">{{fStatus(row.status)}}</el-tag>
            <p v-if="row.failReason">审核不通过原因：{{row.failReason}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="channelName" label="报名渠道"> </el-table-column>

        <el-table-column label="备注" prop="remark">
          <template slot-scope="{ row }">
              <Remark :row="row" :val="{url:'/admin/User/editInfo',pw:'user_list_remark',userId:true}" :getlist="getlist"></Remark>
          </template>
        </el-table-column>
        <el-table-column label="小助手" prop="waId" width="140px">
          <template slot-scope="{ row }">
            <el-select v-model="row.waId" placeholder="小助手" size="medium" multiple :multiple-limit="3" @change="handleInputBlur(row)" :disabled="$store.state.btn_if.indexOf('user_list_service') == -1">
              <el-option :label="item.name" :value="item.id + ''" v-for="(item, index) in $store.state.NormalList" :key="item.id"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="createTime"  label="报名时间">
        </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="{row}">
            <el-button type="text" icon="el-icon-video-camera-solid" size="mini" class="skyblue" @click="watchInfo(row)" v-show="$store.state.btn_if.indexOf('user_get_contact') != -1">查看报名信息</el-button>

            <el-button type="text" icon="el-icon-camera" size="mini" class="green" @click="seeTheLink(row.userId)" v-show="$store.state.btn_if.indexOf('user_get_contact') != -1">查看联系方式</el-button>
            <el-button
              v-if="row.status == 0||row.status ==2"
              type="text"
              icon="el-icon-s-check"
              size="mini"
              class="yellow"
              @click="audit(row,1)"
              v-show="$store.state.btn_if.indexOf('activity_list_check') != -1"
              >审核通过</el-button
            >
            <el-button
              v-if="row.status == 1||row.status==0"
              type="text"
              icon="el-icon-s-check"
              size="mini"
              class="yellow"
              @click="audit(row,2)"
              v-show="$store.state.btn_if.indexOf('activity_list_check') != -1"
              >审核不通过</el-button
            >
            </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.search.page"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="this.search.pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <Dialog ref="dialogRef" :cet="true" :title="'添加活动人员'" :id="3" @getlist="getlist"></Dialog>
    <Dialog ref="dialogRef1" :cet="true" :title="'审核拒绝'" :id="4" @getlist="getlist"></Dialog>
    <Slink ref="link"></Slink>
    <Drawer ref="drawer" :title="'展示表单'"></Drawer>
  </div>
</template>

<script>
let that
// import rules from '../../utils/rules'
import mixins from '@/mixin/list'
import { times } from 'lodash'
export default {
  mixins: [mixins],
  beforeCreate() {
    that = this
  },
  data() {
    return {
      btn: true,
      action: {
        action: 'activity'
      },
      pagesize: 10,
      search: {
        page: 1,
        pageSize: 10,
        keyword:'',
        channelId:'',
        ticketId:'',
        status:'',
        applyDate:[]
      },
      title:'详情页',
      s_form: {
        page: 1,
        pageSize: 10,
        keyword:'',
        channelId:'',
        ticketId:'',
        status:'',
        applyDate:[]
        // order: ''
      },
      forms:[],
      channelList:[],
      ticketList:[]
    }
  },
  updated() {
    //   console.log(this.form);
  },
  components: {
    Remark:()=>import('@/components/remark/index.vue'),
    Dialog:()=>import('@/components/popup.vue'),
    Slink:()=>import('@/components/seeTheLink.vue'),
    Drawer:()=>import('@/components/Drawer')
  },
  methods: {
      seeTheLink(val){

          this.$refs.link.getTheDetails(val)
      },
    handleSizeChange(e) {
      this.pagesize = e
      // console.log(e)
    },
    watchInfo(row){
      console.log(132132);
      this.$refs.drawer.drawer=true
      this.$refs.drawer.interface=4
      // console.log(this.forms);
      // this.$refs.drawer.formList=JSON.parse(this.forms)
      this.$refs.drawer.ruleForm=JSON.parse(row.forms)
    },
   async handleInputBlur(val){
     var {
        data: res
      } = await this.$http.post(
        'https://alpha.yfchuhai.com/admin/User/editInfo',
        {
          ...val,
          id:val.userId,
          waId:val.waId.toString()
        }
      )
       if (res.errorCode == 200) {
        this.$message.success(res.message)
        //  row.pai=false
        //  this.tableData=[...this.tableData]
        this.getlist()
      } else {
        this.$message.error(res.message)
      }
    },
    fStatus: function(val) {
      if(val==0){
        return '待审核'
      }else if(val == -1){
        return '已取消'
      }else if(val == 1) {
        return '审核通过'
      }else if(val == 2){
        return '审核不通过'
      }else if(val ==3){
        return '已签到'
      }
    },
    handleCurrentChange(e){
      this.s_form.page = e
      this.getlist(this.s_form)

    },

    onSearch(){
      // this.search.page = 1
      this.s_form={...this.search}
      this.getlist()
    },
    getlist(val) {
        if(!this.$route.query.id){
            return
        }
      var form = val ? val : this.search
      this.$http
        .get('/admin/Activity/getApplyList', {
          params: {
            ...form,
            applyDate:form.applyDate&&form.applyDate.join('~'),
            activityId:this.$route.query.id,
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.list = res.data.list
            this.list.forEach(item=>{
              if(item.waId){
                item.waId=item.waId.split(',')
              }
            })
            this.total = res.data.totalCount
            this.title=res.data.activity.title
            this.forms=res.data.activity.forms
          } else {
            this.$message.error(res.message)
          }
        })
    },

   async getConfiguration(){
      let {data:res}=await  this.$http.get('/admin/Activity/getChannelList',{
        params:{
          activityId:this.$route.query.id,
          page:1,
          pageSize:100000
        }
      })
      this.channelList=res.data.list
     let {data:resTicket}=await  this.$http.get('/admin/Activity/getTicketList',{
        params:{
          activityId:this.$route.query.id,
          page:1,
          pageSize:100000
        }
      })
      this.ticketList=resTicket.data.list
      // console.log(resTicket);
    },
    
    async add() {
      this.$refs.dialogRef.dialogVisible=true
      this.$refs.dialogRef.form={
        userId:'',
        activityId:this.$route.query.id,
        ticketId:'',
        ticketList:this.ticketList
      }
    },

  async  audit(val,num){
      // console.log(val);
      if(num==1){
      var {data:res}= await this.$http.post('/admin/Activity/applyCheck',{
          id:val.id,
          status:1
        })
        if(res.errorCode==200){
        this.$message.success(res.message)
        this.getlist()
        }else{
        this.$message.error(res.message)
        }
      }else{
        this.$refs.dialogRef1.dialogVisible=true
        this.$refs.dialogRef1.form={
          remark:val.failReason,
          status:2,
          id:val.id
        }
        
      }
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .post('/admin/Activity/managerDel', { id: id })
            .then(res => {
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1&&this.total!=1) {
                  this.search.page--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
  },

  created() {
    // this.getconfig()
    // this.getconfig('user')
    this.getlist()
    this.getConfiguration()
    sessionStorage.setItem('action', 'cooperation')
  },
  mounted() {
    // console.log(rules.req('撒打算'))
    // console.log(this.confing_d)
  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
.out .input {
  width: 18%;
}
.el-card {
  position: relative;
}
.out .right {
  float: right;
}
.box {
  display: inline-block;
  margin: 5px;
}
</style>
