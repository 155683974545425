var myMixin = {
  data() {
    return {
      list: [],
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      title:'加载中...'
    }
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.getlist()
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    handleCurrentChange(e) {
      // this.getlist()

      this.search.page = e

      this.getlist(this.search)
    },
    goBack() {
      history.go(-1)
    }
  }
}
export default myMixin
